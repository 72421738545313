import React from "react";
import classNames from "classnames";

import { Container } from "ui/container";
import { Text } from "ui/text";
import { Pagination } from "ui/pagination";
import { AssociatedScreen, ProductList } from "resources/AudiotekaApi";
import type { Pathname } from "pathnames";

import { ProductGrid } from "./product-grid/product-grid";
import { Sections } from "./sections";
import css from "./collections.module.scss";

interface Props {
  darkMode?: boolean;
  productList: ProductList;
  route: Pathname;
  sections: AssociatedScreen["_embedded"]["app:screen-section"];
  title: string;
}

function BaseContent({ productList, route, darkMode }: Omit<Props, "sections" | "title">) {
  return (
    <>
      <ProductGrid darkMode={darkMode} productList={productList} />
      <Pagination
        className={css.pagination}
        darkMode={darkMode}
        page={productList.page}
        pages={productList.pages}
        route={route}
      />
    </>
  );
}

export function ProductCollection(props: Props) {
  return (
    <div className={classNames(css.collection, { [css.collection__darkMode]: props.darkMode })}>
      {props.sections.length === 0 ? (
        <Container>
          <Text as="h1" className={css.title} type="heading3">
            {props.title}
          </Text>
          <BaseContent {...props} />
        </Container>
      ) : (
        <Sections sections={props.sections} title={props.title}>
          <Container>
            <BaseContent {...props} />
          </Container>
        </Sections>
      )}
    </div>
  );
}
