import React, { ComponentProps } from "react";
import Head from "next/head";
import type { GetServerSideProps } from "next/types";
import { useTranslations } from "next-intl";

import { Breadcrumbs } from "ui/breadcrumbs";
import { ProductCollection } from "modules/collections";
import { getScreenSections, getScreenTheme } from "modules/collections/utils";
import { getCycle, getCycleProductsList } from "resources/AudiotekaApi";
import { getLocationInfo } from "utils/getLocationInfo";
import { removeEmptyValues } from "utils/object";
import { buildLink } from "utils/buildLink";

type PageProps = ComponentProps<typeof ProductCollection>;
type PageParams = { id: string; locale: string };

export default function CyclePage(props: PageProps) {
  const t = useTranslations();

  return (
    <>
      <Head>
        <title>{t("meta.cycle.title", { cycle: props.title })}</title>
        <meta name="description" content={t("meta.cycle.description", { cycle: props.title })} />
      </Head>
      <Breadcrumbs items={[{ name: props.title }]} />
      <ProductCollection {...props} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps<PageProps, PageParams> = async ({
  params: { id, locale },
  query: { page = "1" },
}) => {
  const { catalogId } = getLocationInfo(locale);

  try {
    const { data: cycle } = await getCycle(id, catalogId, { embed_screen: 1 });

    if (cycle.slug && cycle.slug !== id) {
      return {
        redirect: {
          destination: buildLink("cycle", locale, { id: cycle.slug }),
          permanent: true,
        },
      };
    }

    const { data: productList } = await getCycleProductsList(
      cycle.id,
      catalogId,
      removeEmptyValues({ limit: 24, page })
    );

    if (!productList._embedded?.["app:product"]) {
      return { notFound: true };
    }

    return {
      props: {
        _robots: cycle.cycle_type === "marketing" ? "noindex" : "",
        title: cycle.name,
        productList,
        route: "cycle",
        darkMode: getScreenTheme(cycle) === "dark",
        sections: page === "1" ? getScreenSections(cycle) : [],
      },
    };
  } catch (error) {
    if (error?.response?.status === 404) {
      return { notFound: true };
    }

    throw new Error(error.message); // To display 500 page
  }
};
